<template>
  <el-form
      ref="newsFormRef"
      :model="newsForm"
      :rules="newsFormRules"
      label-width="80px"
      class="ruleForm"
  >
    <el-form-item
        label="标题"
        prop="title"
    >
      <el-input v-model="newsForm.title"/>
    </el-form-item>

    <el-form-item
        label="作者"
        prop="author"
    >
      <el-input v-model="newsForm.author"/>
    </el-form-item>

    <el-form-item
        label="来源"
        prop="source"
    >
      <el-input v-model="newsForm.source"/>
    </el-form-item>

    <el-form-item
        label="内容"
        prop="content"
    >
      <editor @event="handleChange" :content="newsForm.content" v-if="newsForm.content"/>
    </el-form-item>

    <el-row>
      <el-col :span="12">
        <el-form-item
            label="封面"
            prop="cover"
        >
          <Upload
              :avatar="newsForm.cover"
              @change="handleUploadChange"
              :width="384"
              :height="192"
          />
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-col :span="24">
          <el-form-item
              label="热点标题"
              prop="hot"
          >
            <el-select
                v-model="newsForm.hot"
                class="m-2"
                placeholder="请选择热点专题"
            >
              <el-option
                  v-for="item in hotList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item
              label="新闻类别"
              prop="category"
          >
            <el-select
                v-model="newsForm.category"
                class="m-2"
                placeholder="Select"
                style="width:100%"
            >
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-form-item>
          <el-button
              type="primary"
              @click="submitForm()"
              style="margin: 40px 0 0;"
          >保存
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script setup>
import {ref, reactive, watch, onMounted} from "vue";
import editor from "@/components/editor/Editor";
import Upload from "@/components/upload/Upload";
import upload from '@/utils/upload';
import {useRoute, useRouter} from 'vue-router';
import axios from 'axios';
import store from "@/store";
import {ElMessage} from "element-plus";
import {uploadToAliyun} from "@/utils/uploadService";
import { isEqual } from 'lodash';

const props = defineProps({
  id: {
    type: [String, Number],
    default: null
  }
});

const route = useRoute();
const router = useRouter(); // 引入并使用 useRouter

const newsFormRef = ref();
const newsForm = reactive({
  category: 1, //1最新动态 2纪检监察 3通知公告 4先锋圳上
  hot: 0, // 初始化为 0
  title: "",
  author: "",
  source: "",
  content: "",
  cover: "",
});

const originalNewsForm = reactive({}); // 原始表单数据

const newsFormRules = reactive({
  title: [{required: true, message: "请输入标题", trigger: "blur"}],
  author: [{required: true, message: "请输入作者", trigger: "blur"}],
  content: [{required: true, message: "请输入内容", trigger: "blur"}],
  category: [{required: true, message: "请选择分类", trigger: "blur"}],
  cover: [{required: true, message: "请上传图片", trigger: "blur"}],
  hot: [
    {
      validator: (rule, value, callback) => {
        if (value !== 0 && (value < 1 || value > 6)) {
          callback(new Error("请选择热点专题"));
        } else {
          callback();
        }
      },
      trigger: "change"
    }
  ]
});

// 每次editor内容改变的回调
const handleChange = data => {
  newsForm.content = data;
};

// 新闻类别
const options = [
  {label: "最新动态", value: 1},
  {label: "纪检监察", value: 2},
  {label: "通知公告", value: 3},
  {label: "先锋圳上", value: 4},
  {label: "工作动态", value: 5},
  {label: "政府工作报告", value: 6}
];

const handleUploadChange = (file) => {
  newsForm.cover = URL.createObjectURL(file);
  newsForm.file = file
};

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 提交表单
const submitForm = async () => {
  newsFormRef.value.validate(async (valid) => {
    if (valid) {
      // 如果更新了封面，更新 newsForm 中的文件路径为阿里云返回的文件 URL
      if (newsForm.file) {
        newsForm.cover = await uploadToAliyun('newsuploads', newsForm.file);
        delete newsForm.file;
      }

      // 比较表单数据和原始数据
      if (isEqual(newsForm, originalNewsForm)) {
        ElMessage.warning("表单数据未修改，无需提交");
        return;
      }

      if (props.id) {
        // 修改新闻数据
        await axios.post(baseUrl + "/adminapi/news/edit", newsForm);
        ElMessage.success("更新成功");
        Object.assign(originalNewsForm, newsForm); // 更新原始表单数据
      } else {
        // 添加新闻数据
        newsForm.operator = store.state.userInfo.userId;
        await axios.post(baseUrl + "/adminapi/news/add", newsForm);
        ElMessage.success("新增成功");
      }
      await router.push('/news-manage/newslist')
    }
  })
};

// 初始化热点专题选项
const hotList = ref([]);

// 监听路由参数的变化，并根据新的 id 获取数据
watch(() => props.id, async (newId) => {
  if (newId) {
    try {
      const res = await axios.get(`${baseUrl}/adminapi/news/list/${newId}`);
      Object.assign(newsForm, res.data.data[0]);
      Object.assign(originalNewsForm, res.data.data[0]); // 存储原始表单数据
    } catch (error) {
      console.error("获取数据失败:", error);
    }
  } else {
    // 如果没有 id，则重置表单
    Object.assign(newsForm, {content: "请输入正文"});
    Object.assign(originalNewsForm, {content: "请输入正文"}); // 重置原始表单数据
  }
}, {immediate: true});

// 获取热点专题数据的函数
const fetchHotList = async () => {
  try {
    const res1 = await axios.get(`${baseUrl}/adminapi/newsHot/title`);
    if (res1.data.data && res1.data.data.length > 0) {
      hotList.value = [
        { label: "请选择热点专题", value: 0 }, // 默认选项
        ...res1.data.data.map(item => ({
          label: item.title,
          value: item.category, // 假设后端返回的热点标题有 id 和 title 字段
        }))
      ];
    }
  } catch (error) {
    console.error('获取热点专题数据失败:', error);
  }
};

// 在组件挂载时调用获取热点专题数据的函数
onMounted(fetchHotList);
</script>

<style lang="scss" scoped>

</style>
