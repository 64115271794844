<template>
  <router-view /> <!-- 渲染当前路由对应的组件 -->
</template>

<script setup>

</script>

<style lang="scss">
// 全局样式
body {
  background-color: #f8f8f8;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}

a {
  text-decoration: none; /* 取消下划线 */
  color: inherit; /* 继承父元素的颜色 */
}

// 容器样式
.container-style {
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  border-radius: 6px;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

/* 滚动条样式 */
::-webkit-scrollbar {
  width: 12px; /* 轨道宽度 */
  height: 12px; /* 轨道高度 */
  position: absolute;
}

::-webkit-scrollbar-thumb {
  background: #fdfdfd;
  border-radius: 6px; /* 圆角 */
  border: 1px solid #ccc; /* 边框 */
}

::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 6px; /* 圆角 */
}

</style>
