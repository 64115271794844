<template>
  <el-aside :width="$store.state.isCollapsed?'64px':'240px'">
    <!-- 侧边栏折叠 -->
    <el-menu
        :collapse="$store.state.isCollapsed"
        :collapse-transition="false"
        :default-active="route.fullPath"
        :router="true"
    >
      <!-- 首页菜单项 -->
      <el-menu-item index="/index">
        <el-icon>
          <home-filled/>
        </el-icon>
        <span>首页</span>
      </el-menu-item>

      <!-- 个人中心菜单项 -->
      <el-menu-item index="/center">
        <el-icon>
          <avatar/>
        </el-icon>
        <span>个人中心</span>
      </el-menu-item>

      <!-- 用户管理子菜单 -->
      <el-sub-menu v-admin index="/user-manage">
        <template #title>
          <el-icon>
            <user-filled/>
          </el-icon>
          <span>用户管理</span>
        </template>
        <el-menu-item index="/user-manage/adduser">添加用户</el-menu-item>
        <el-menu-item index="/user-manage/userlist">用户列表</el-menu-item>
      </el-sub-menu>

      <!-- 新闻管理子菜单 -->
      <el-sub-menu index="/news-manage">
        <template #title>
          <el-icon><Reading /></el-icon>
          <span>新闻管理</span>
        </template>
        <el-menu-item index="/news-manage/addnews">创建新闻</el-menu-item>
        <el-menu-item index="/news-manage/newslist">新闻列表</el-menu-item>
        <el-menu-item index="/news-manage/newsHotAdd">创建专题</el-menu-item>
        <el-menu-item index="/news-manage/newsHotList">专题列表</el-menu-item>
      </el-sub-menu>

      <!-- 图片管理子菜单 -->
      <el-sub-menu index="/picture-manage">
        <template #title>
          <el-icon><PictureFilled /></el-icon>
          <span>图片管理</span>
        </template>
        <el-menu-item index="/picture-manage/carouselAdd">添加轮播图</el-menu-item>
        <el-menu-item index="/picture-manage/carouselList">轮播图列表</el-menu-item>
        <el-menu-item index="/picture-manage/ImageCleanup">清理后端图片</el-menu-item>
      </el-sub-menu>

      <!-- 部门管理子菜单 -->
      <el-sub-menu v-admin index="/department-manage">
        <template #title>
          <el-icon>
            <Share/>
          </el-icon>
          <span>部门管理</span>
        </template>
        <el-menu-item index="/department-manage/adddepartment">添加部门</el-menu-item>
        <el-menu-item index="/department-manage/departmentlist">部门列表</el-menu-item>
      </el-sub-menu>

      <!-- 人员管理子菜单 -->
      <el-sub-menu v-admin index="/staff-manage">
        <template #title>
          <el-icon><Avatar /></el-icon>
          <span>人员管理</span>
        </template>
        <el-menu-item index="/staff-manage/addstaff">添加人员</el-menu-item>
        <el-menu-item index="/staff-manage/stafflist">人员列表</el-menu-item>
      </el-sub-menu>

      <!-- 部门管理子菜单 -->
      <el-sub-menu v-admin index="/establishment-manage">
        <template #title>
          <el-icon><Connection /></el-icon>
          <span>编制管理</span>
        </template>
        <el-menu-item index="/establishment-manage/establishmentAdd/">添加编制</el-menu-item>
        <el-menu-item index="/establishment-manage/establishmentList">编制列表</el-menu-item>
      </el-sub-menu>

      <!-- 反馈管理子菜单 -->
      <el-menu-item index="/feedback-manage/feedbacklist">
        <el-icon>
          <Message/>
        </el-icon>
        <span>意见建设</span>
      </el-menu-item>

      <!-- 系统信息菜单项 -->
      <el-menu-item v-admin index="/systemedit">
        <el-icon>
          <Setting/>
        </el-icon>
        <span>系统信息</span>
      </el-menu-item>
    </el-menu>
  </el-aside>
</template>

<script setup>
// 引入图标组件
import {HomeFilled, Avatar, UserFilled, Reading ,PictureFilled, Share,Setting,Connection,Message} from "@element-plus/icons-vue";
import {useRoute} from "vue-router"; // 用于获取当前路由
import {useStore} from "vuex"; // 用于访问 Vuex store

// 获取当前路由
const route = useRoute();
// 获取 Vuex store
const store = useStore();
// 自定义指令 v-admin 用于控制用户管理菜单的显示
const vAdmin = {
  mounted(el) {
    if (store.state.userInfo.role !== 1) {
      el.parentNode.removeChild(el);
    }
  },
};
</script>

<style lang="scss" scoped>
.el-aside {
  height: 100vh;

  .el-menu {
    height: 100vh;
  }
}
</style>

