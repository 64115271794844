<template>
  <el-form
      ref="newsHotFormRef"
      :model="newsHotForm"
      :rules="newsHotFormRules"
      label-width="96px"
  >
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item
            label="专题分类"
            prop="category"
        >
          <el-input v-model.number="newsHotForm.category"/>
        </el-form-item>
      </el-col>

      <el-col :span="24">
        <el-form-item
            label="专题标题"
            prop="title"
        >
          <el-input v-model="newsHotForm.title"/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20" style="min-width:1260px ">
      <el-col :span="10">
        <el-form-item
            label="专题封面"
            prop="cover"
        >
          <Upload
              :avatar="newsHotForm.cover"
              @change="handleUploadChange"
              :width="384"
              :height="192"
          />
        </el-form-item>
      </el-col>

      <el-col :span="14">
        <span>可以先添加图片，当专题标题为空时，会自动添加为图片名称为专题标题。专题标题也可以手动添加和修改。图片最佳尺寸为 384*192，不大于 50KB</span>
      </el-col>
    </el-row>
    <el-row>
      <el-form-item>
        <el-button
            type="primary"
            @click="submitForm()"
        >保存
        </el-button>
      </el-form-item>
    </el-row>
  </el-form>
</template>

<script setup>
import { ref, reactive, watch, onMounted } from "vue";
import Upload from "@/components/upload/Upload";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import {ElMessage} from "element-plus";
import {uploadToAliyun} from "@/utils/uploadService";
import { isEqual } from 'lodash';

const router = useRouter();
const route = useRoute();
const newsHotFormRef = ref();

// 定义 props，接收可选的 id
const props = defineProps({
  id: {
    type: String,
    required: false,
    default: null
  }
});

// 定义数据
const newsHotForm = reactive({
  title: "",
  category: 1, // 默认值设置为1
  cover: "",
});

// 保存初始表单数据
const initialNewsHotForm = reactive({
  title: "",
  category: 1,
  cover: "",
});

// 自定义验证器
const validateCategory = (rule, value, callback) => {
  if (!value || value < 1 || value > 6) {
    callback(new Error("分类必须在1到6之间"));
  } else {
    callback();
  }
};

// 表单验证规则
const newsHotFormRules = reactive({
  title: [{required: true, message: "请输入标题", trigger: "blur"}],
  category: [
    {required: true, message: "请选择分类", trigger: "blur"},
    {validator: validateCategory, trigger: "blur"}
  ],
  cover: [{required: true, message: "请上传图片", trigger: "blur"}]
});

// 上传图片的函数
const handleUploadChange = (file) => {
  newsHotForm.cover = URL.createObjectURL(file);
  newsHotForm.file = file;

  // 只有在专题标题没有内容时才自动添加
  if (!newsHotForm.title) {
    // 提取图片名称并去除扩展名
    const fileName = file.name;
    newsHotForm.title = fileName.substring(0, fileName.lastIndexOf('.'));
  }
};

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 提交表单
const submitForm = () => {
  newsHotFormRef.value.validate(async (valid) => {
    if (valid) {
      // 比较表单数据与初始状态
      if (isEqual(newsHotForm, initialNewsHotForm)) {
        ElMessage.warning("表单未修改，无需保存");
        return;
      }

      // 如果更新了封面，更新 newsHotForm 中的文件路径为阿里云返回的文件 URL
      if (newsHotForm.file) {
        newsHotForm.cover = await uploadToAliyun('newshotuploads', newsHotForm.file);
        delete newsHotForm.file;
      }

      // 最多六条记录，所以有就直接覆盖，没有添加
      await axios.post(`${baseUrl}/adminapi/newsHot/add`, newsHotForm);
      await router.push(`/news-manage/newsHotList`);
      ElMessage.success("保存成功");
    }
  });
};

// 监听路由参数的变化，并根据新的 id 获取数据
watch(() => props.id, async (newId) => {
  if (newId) {
    try {
      const response = await axios.get(`${baseUrl}/adminapi/newsHot/list/${props.id}`);
      Object.assign(newsHotForm, response.data.data);
      Object.assign(initialNewsHotForm, response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  } else {
    // 如果没有 id，重置表单和初始状态
    Object.assign(newsHotForm, {
      title: "",
      category: 1,
      cover: "",
    });
    Object.assign(initialNewsHotForm, {
      title: "",
      category: 1,
      cover: "",
    });
  }
}, {immediate: true});

// 在组件挂载时保存初始状态
onMounted(() => {
  Object.assign(initialNewsHotForm, newsHotForm);
});
</script>

<style lang="scss" scoped>

</style>
