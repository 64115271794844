import Home from '@/views/home/Home.vue'
import Center from '@/views/center/Center.vue'
import UserAdd from '@/views/user-manage/UserAdd.vue'
import UserList from '@/views/user-manage/UserList.vue'

import NewsAdd from '@/views/news-manage/NewsAdd.vue'
import NewsList from '@/views/news-manage/NewsList.vue'
import NewsEdit from '@/views/news-manage/NewsEdit.vue'

import NewsPreview from '@/views/news-manage/NewsPreview.vue'

import NewsHotAdd from '@/views/news-manage/NewsHotAdd.vue'
import NewsHotEdit from '@/views/news-manage/NewsHotEdit.vue'
import NewsHotList from '@/views/news-manage/NewsHotList.vue'

import CarouselList from '@/views/picture-manage/CarouselList.vue'
import CarouselAdd from '@/views/picture-manage/CarouselAdd.vue'
import CarouselEdit from '@/views/picture-manage/CarouselEdit.vue'
import ImageCleanup from '@/views/picture-manage/ImageCleanup.vue'

import FeedbackList from '@/views/feedback-manage/FeedbackList.vue'
import FeedbackEdit from "@/views/feedback-manage/FeedbackEdit.vue";
import EstablishmentAdd from "@/views/establishment-manage/EstablishmentAdd.vue";
import EstablishmentEdit from "@/views/establishment-manage/EstablishmentEdit.vue";
import EstablishmentList from "@/views/establishment-manage/EstablishmentList.vue";

import InstitutionAdd from "@/views/department-manage/DepartmentAdd.vue";
import InstitutionEdit from "@/views/department-manage/DepartmentEdit.vue";
import InstitutionList from "@/views/department-manage/DepartmentList.vue";

import StaffAdd from "@/views/Staff-manage/StaffAdd.vue";
import StaffEdit from "@/views/Staff-manage/StaffEdit.vue";
import StaffList from "@/views/Staff-manage/StaffList.vue";

import SystemEdit from '@/views/system/SystemEdit.vue'
import NotFound from '@/views/notfound/NotFound.vue'

const routes = [
    {path: "/index", component: Home},
    {path: "/center", component: Center},
    {path: "/user-manage/adduser", component: UserAdd, requireAdmin: true},
    {path: "/user-manage/userlist", component: UserList, requireAdmin: true},
    
    {path: "/news-manage/addnews", component: NewsAdd},
    {path: "/news-manage/newslist", component: NewsList},
    {path: "/news-manage/editnews/:id", component: NewsEdit},
    
    {path: "/news-manage/newsPreview/:id", component: NewsPreview},
    
    {path: "/news-manage/newsHotAdd", component: NewsHotAdd},
    {path: "/news-manage/newsHotEdit/:id", component: NewsHotEdit},
    {path: "/news-manage/newsHotList", component: NewsHotList},
    
    {path: "/picture-manage/carouselAdd", component: CarouselAdd},
    {path: "/picture-manage/carouselList", component: CarouselList},
    {path: "/picture-manage/carouselEdit/:id", component: CarouselEdit},
    {path: "/picture-manage/ImageCleanup", component: ImageCleanup},
    
    {path: "/department-manage/adddepartment", component: InstitutionAdd},
    {path: "/department-manage/departmentlist", component: InstitutionList},
    {path: "/department-manage/editdepartment/:id", component: InstitutionEdit},
    
    {path: "/systemedit", component: SystemEdit},
    
    {path: "/feedback-manage/feedbacklist", component: FeedbackList},
    {path: "/feedback-manage/editfeedback/:id", component: FeedbackEdit},
    
    {path: "/staff-manage/addstaff", component: StaffAdd},
    {path: "/staff-manage/stafflist", component: StaffList},
    {path: "/staff-manage/editstaff/:id", component: StaffEdit},
    
    {path: "/establishment-manage/establishmentAdd", component: EstablishmentAdd},
    {path: "/establishment-manage/establishmentEdit", component: EstablishmentEdit},
    {path: "/establishment-manage/establishmentList", component: EstablishmentList},
    
    {path: "/", redirect: "/index"},
    {path: "/:pathMatch(.*)*", name: "Notfound", component: NotFound}
]

export default routes