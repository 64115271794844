import axios from "axios";

// 定义文件类型和大小限制（可选）
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'application/pdf']; // 示例允许的文件类型

async function upload(path, userForm) {
    try {
        // 创建 FormData 对象
        const params = new FormData();
        
        // 遍历 userForm 并添加到 FormData 中
        for (let key in userForm) {
            if (userForm.hasOwnProperty(key)) {
                const value = userForm[key];
                
                // 如果是文件，进行验证
                if (value instanceof File) {
                    if (value.size > MAX_FILE_SIZE) {
                        throw new Error('文件大小超过限制');
                    }
                    if (!ALLOWED_FILE_TYPES.includes(value.type)) {
                        throw new Error('不允许的文件类型');
                    }
                }
                
                params.append(key, value);
            }
        }

        // 发送 POST 请求
        const response = await axios.post(path, params, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });

        return response.data;
    } catch (error) {
        // 捕获并处理错误
        console.error('上传文件时出错:', error.message);
        throw error;
    }
}

export default upload;
