<template>
  <div>
    <el-page-header
        content="首页"
        icon=""
        title="网站后台管理系统"
    />

    <el-card class="box-card">
      <el-row>
        <el-col :span="4">
          <el-avatar :size="100" :src="avatarUrl"/>
        </el-col>
        <el-col :span="20">
          <h3 style="line-height:100px;">欢迎 {{ store.state.userInfo.username }} 回来, {{ welcomeText }}</h3>
        </el-col>
      </el-row>
    </el-card>

    <el-card class="box-card">
      <el-row>
        <el-col :span="7">
          <h4>用户：{{ store.state.userInfo.username }}</h4>
          <h4>角色：{{ roleText }}</h4>
        </el-col>

        <el-col :span="8">
          <h4>昵称：{{ store.state.userInfo.nickname }}</h4>
          <h4>登录次数：{{ store.state.userInfo.logins }}</h4>
        </el-col>

        <el-col :span="9">
          <h4>注册时间：{{
              store.state.userInfo.createdAt ? formatTime.getTime(store.state.userInfo.createdAt) : ""
            }}</h4>
          <h4>最近登录：{{
              store.state.userInfo.updatedAt ? formatTime.getTime(store.state.userInfo.updatedAt) : ""
            }}</h4>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script setup>
import {useStore} from "vuex";
import {computed} from 'vue';
import {getDefaultAvatar} from "@/utils/getDefaultAvatar";
import formatTime from "@/utils/formatTime";

const store = useStore();

const avatarUrl = computed(
    () =>
        store.state.userInfo.avatar
            ? store.state.userInfo.avatar
            : getDefaultAvatar(store.state.userInfo.gender)
);

const welcomeText = computed(() => new Date().getHours() < 18 ? "认认真真干活。" : "休息好才能工作好。");

const roleText = store.state.userInfo.role === 1 ? '管理员' :
    store.state.userInfo.role === 2 ? '总编辑' :
        store.state.userInfo.role === 3 ? '编辑' :
            store.state.userInfo.role === 4 ? '纪检' :
                store.state.userInfo.role === 5 ? '普通用户' : '未知角色';
</script>

<style lang="scss" scoped>
.box-card {
  padding-left: 20px;
  margin-top: 30px;
}

.el-carousel__item h3 {
  color: white;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

h3, h4 {
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏溢出部分 */
  text-overflow: ellipsis; /* 显示省略号 */
}

h4 {
  height: 50px;
  line-height: 50px;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
