<template>
  <div>
    <el-page-header
      content="系统信息"
      icon=""
      title="网站后台管理系统"
    />

    <el-row
      :gutter="20"
      class="el-row"
    >
      <el-col :span="12">
        <el-card>
          <template #header>
            <div class="card-header">
              <span>单位信息</span>
            </div>
          </template>

          <el-form
            ref="departmentFormRef"
            :model="departmentForm"
            :rules="departmentFormRules"
            label-width="120px"
            class="ruleForm"
          >
            <el-form-item
              label="单位名称"
              prop="unit"
            >
              <el-input v-model="departmentForm.unit" />
            </el-form-item>

            <el-form-item
              label="部门简介"
              prop="description"
            >
              <el-input v-model="departmentForm.description" />
            </el-form-item>

            <el-form-item
              label="地址"
              prop="address"
            >
              <el-input v-model="departmentForm.address" />
            </el-form-item>

            <el-form-item
              label="电话"
              prop="phone"
            >
              <el-input v-model="departmentForm.phone" />
            </el-form-item>

            <el-form-item
              label="邮箱"
              prop="email"
            >
              <el-input v-model="departmentForm.email" />
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>

      <el-col :span="12">
        <el-card>
          <template #header>
            <div class="card-header">
              <span>软件信息</span>
            </div>
          </template>

          <el-form
            ref="softwareFormRef"
            :model="softwareForm"
            :rules="softwareFormRules"
            label-width="120px"
            class="ruleForm"
          >
            <el-form-item
              label="备案信息"
              prop="icp"
            >
              <el-input v-model="softwareForm.icp" />
            </el-form-item>

            <el-form-item
              label="码农"
              prop="writer"
            >
              <el-input v-model="softwareForm.writer" />
            </el-form-item>

            <el-form-item
              label="版权信息"
              prop="copyright"
            >
              <el-input v-model="softwareForm.copyright" />
            </el-form-item>

            <el-form-item
              label="运维电话"
              prop="technicalSupportLine"
            >
              <el-input v-model="softwareForm.technicalSupportLine" />
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm()"
              >更新
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { ElMessage } from 'element-plus';
import axios from 'axios';

const departmentFormRef = ref(null);
const softwareFormRef = ref(null);

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 初始化表单数据
const departmentForm = ref({
  unit: '',
  description: '',
  address: '',
  phone: '',
  email: ''
});

const softwareForm = ref({
  icp: '',
  writer: '',
  copyright: '',
  technicalSupportLine: ''
});

// 表单验证规则
const departmentFormRules = {
  unit: [{ required: true, message: '请输入单位名称', trigger: 'blur' }],
  description: [{ required: true, message: '请输入部门简介', trigger: 'blur' }],
  address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
  phone: [{ required: true, message: '请输入电话', trigger: 'blur' }],
  email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }]
};

// 表单验证规则
const softwareFormRules = {
  icp: [{ required: true, message: '请输入备案信息', trigger: 'blur' }],
  writer: [{ required: true, message: '请输入编码信息', trigger: 'blur' }],
  copyright: [{ required: true, message: '请输入版权信息', trigger: 'blur' }],
  technicalSupportLine: [{ required: true, message: '请输入运维电话', trigger: 'blur' }]
};

// 加载数据
onMounted(async () => {
  try {
    const response = await axios.get(`${baseUrl}/adminapi/systemInfo/list`);
    if (response.data.ActionType === "OK") {
      departmentForm.value = response.data.data[0] || {};
      softwareForm.value = response.data.data[0] || {};
    } else {
      ElMessage.error("获取数据失败，请检查网络或稍后再试！");
    }
  } catch (error) {
    ElMessage.error("获取数据失败，请检查网络或稍后再试！");
    console.error("Error fetching data:", error);
  }
});

// 提交表单
const submitForm = async () => {
  try {
    const departmentValid = await departmentFormRef.value.validate();
    const softwareValid = await softwareFormRef.value.validate();

    if (departmentValid && softwareValid) {
      const response = await axios.post(`${baseUrl}/adminapi/systemInfo/upload`, {
        ...departmentForm.value,
        ...softwareForm.value
      });

      if (response.data.ActionType === "OK") {
        ElMessage.success("更新成功");
      } else {
        ElMessage.error("更新失败");
      }
    }
  } catch (error) {
    console.error("Error submitting form:", error);
  }
};
</script>

<style scoped lang="scss">
.el-row {
  margin-top: 20px;

  .box-card {
    text-align: center;
  }

  h5 {
    margin-top: 8px;
  }

  button {
    margin: 0 0 0 auto;
  }
}
</style>
