<template>
  <el-form
      ref="carouselFormRef"
      :model="carouselForm"
      :rules="carouselFormRules"
      label-width="96px"
  >
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item
            label="图片位置"
            prop="position"
        >
          <el-select v-model="carouselForm.position" placeholder="请选择图片位置">
            <el-option
                v-for="item in positionOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="24">
        <el-form-item
            label="轮播顺序"
            prop="order"
        >
          <el-input v-model.number="carouselForm.order"/>
        </el-form-item>
      </el-col>

      <el-col :span="24">
        <el-form-item
            label="图片标题"
            prop="title"
        >
          <el-input v-model="carouselForm.title"/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20" style="min-width:1260px ">
      <el-col :span="10">
        <el-form-item
            label="轮播图片"
            prop="cover"
        >
          <Upload
              :avatar="carouselForm.cover"
              @change="handleUploadChange"
              :width="384"
              :height="192"
          />
        </el-form-item>
      </el-col>
      <el-col :span="14">
        <span>可以先添加图片，当图片标题为空时，会自动添加为图片名称为图片标题，图片标题也可以手动添加和修改。主页图片最佳尺寸为 600*399；先锋圳上图片最佳尺寸为 600*360；纯朴圳上图片最佳尺寸为 1200*362</span>
      </el-col>
    </el-row>

    <el-row>
      <el-form-item>
        <el-button
            type="primary"
            @click="submitForm()"
        >保存
        </el-button>
      </el-form-item>
    </el-row>
  </el-form>
</template>

<script setup>
import { ref, reactive, watch } from "vue";
import Upload from "@/components/upload/Upload";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { ElMessage } from "element-plus";
import { uploadToAliyun } from "@/utils/uploadService";
import { isEqual } from 'lodash';

const router = useRouter();
const route = useRoute();
const carouselFormRef = ref();

// 定义 props，接收可选的 id
const props = defineProps({
  id: {
    type: String,
    required: false,
    default: null
  }
});

// 定义数据
const carouselForm = reactive({
  title: "",
  order: 1, // 默认值设置为1
  cover: "",
  position: "home", // 默认值设置为 "home"
});

// 定义初始数据副本
const initialCarouselForm = reactive({ ...carouselForm });

// 定义 position 选项
const positionOptions = [
  { value: "home", label: "首页轮播图" },
  { value: "pioneer", label: "先锋圳上轮播图" },
  { value: "gozsz", label: "纯朴圳上轮播图" }
];

// 表单验证规则
const carouselFormRules = reactive({
  title: [{ required: true, message: "请输入标题", trigger: "blur" }],
  cover: [{ required: true, message: "请上传图片", trigger: "blur" }],
  position: [{ required: true, message: "请选择图片位置", trigger: "blur" }]
});

// 上传图片的函数
const handleUploadChange = (file) => {
  carouselForm.cover = URL.createObjectURL(file);
  carouselForm.file = file;

  // 只有当图片标题为空时才自动添加
  if (!carouselForm.title) {
    // 提取文件名并去掉扩展名
    const fileName = file.name;
    carouselForm.title = fileName.substring(0, fileName.lastIndexOf('.'));
  }
};

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 提交表单
const submitForm = () => {
  carouselFormRef.value.validate(async (valid) => {
    if (valid) {
      // 如果数据未修改，不提交更新
      if (isEqual(carouselForm, initialCarouselForm)) {
        ElMessage.info("数据未修改，无需提交");
        return;
      }

      // 如果更新了封面，更新 carouselForm 中的文件路径为阿里云返回的文件 URL
      if (carouselForm.file) {
        carouselForm.cover = await uploadToAliyun('carouseluploads', carouselForm.file);
        delete carouselForm.file;
        console.log(carouselForm.cover);
      }

      if (props.id) {
        const url = `${baseUrl}/adminapi/carousel/edit/${props.id}`;
        await axios.post(url, carouselForm);
        ElMessage.success("修改成功");
      } else {
        const url = `${baseUrl}/adminapi/carousel/add`;
        await axios.post(url, carouselForm);
        ElMessage.success("添加成功");
      }
      await router.push(`/picture-manage/carouselList`);
    }
  });
};

// 监听路由参数的变化，并根据新的 id 获取数据
watch(() => props.id, async (newId) => {
  if (newId) {
    try {
      const response = await axios.get(`${baseUrl}/adminapi/carousel/list/${props.id}`);
      Object.assign(carouselForm, response.data.data);
      // 更新初始数据副本
      Object.assign(initialCarouselForm, response.data.data);
      console.log(carouselForm);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  } else {
    // 如果没有 id，重置表单和初始数据副本
    Object.assign(carouselForm, {
      title: "",
      order: 1,
      cover: "",
      position: "home"
    });
    Object.assign(initialCarouselForm, carouselForm);
  }
}, { immediate: true });
</script>

<style lang="scss" scoped>

</style>
