<template>
    <el-upload
        class="avatar-uploader"

        :show-file-list="false"
        :auto-upload="false"
        :on-change="handleChange"
    >
        <img
            v-if="props.avatar"
            :src="props.avatar"
            class="avatar"
            :style="{ width: `${props.width}px`, height: `${props.height}px` }"
        />
        <el-icon
            v-else
            class="avatar-uploader-icon"
            :style="{ width: `${props.width}px`, height: `${props.height}px` }"
        >
            <Plus />
        </el-icon>
    </el-upload>
</template>

<script setup>
import { Plus } from "@element-plus/icons-vue";

// 定义 props
const props = defineProps({
    avatar: String,
    width: {
        type: Number,
        default: 206
    },
    height: {
        type: Number,
        default: 298
    }
});

const emit = defineEmits(["change"]);

const handleChange = file => {
    emit("change", file.raw);
};
</script>

<style lang="scss" scoped>
::v-deep(.el-upload) {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

::v-deep(.el-upload:hover) {
  border-color: var(--el-color-primary);
}

::v-deep(.el-icon.avatar-uploader-icon) {
  font-size: 28px;
  color: #8c939d;
  text-align: center;
}

.avatar {
  object-fit: cover; // 确保图片按比例缩放并覆盖整个区域
}
</style>
