<!-- 新增新闻，调用新闻表单 -->
<template>
  <el-page-header
      content="创建新闻"
      icon=""
      title="新闻管理"
      style="margin-bottom: 30px;"
  />
  <NewsForm/>
</template>

<script setup>
import {useRouter, useRoute} from 'vue-router'
import NewsForm from "@/views/news-manage/NewsForm.vue";

const router = useRouter()
const route = useRoute()
</script>

<style lang="scss" scoped>

</style>