<template>
  <div>
    <el-page-header
        content="反馈回复"
        @back="handleBack()"
        title="反馈管理"
    />

    <el-form
        ref="feedbackFormRef"
        :model="feedbackForm"
        :rules="feedbackFormRules"
        label-width="100px"
        class="ruleForm"
    >
      <el-row>
        <el-col :span="2"></el-col>

        <el-col :span="5">
          <el-form-item label="用户：">
            <span>{{ feedbackForm.username }}</span>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item label="联系方式：">
            <span>{{ feedbackForm.contactInformation }}</span>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item label="是否公开">
            <el-switch
                v-model="feedbackForm.isPublic"
                :active-value="1"
                :inactive-value="0"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="反馈内容">
        <pre class="feedback-content">{{ feedbackForm.feedbackContent }}</pre>
      </el-form-item>

      <el-form-item label="回复内容" prop="replyContent">
        <textarea
            v-model="feedbackForm.replyContent"
            rows="10"
            class="reply-content"
            required
        ></textarea>
      </el-form-item>

      <el-form-item>
        <el-button
            type="primary"
            @click="submitForm()"
        >提交回复
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>


<script setup>
import {ref, reactive, onMounted} from "vue";
import {useRouter, useRoute} from "vue-router";
import axios from "axios";
import {ElMessage} from "element-plus";

const router = useRouter();
const route = useRoute();
const feedbackFormRef = ref();

// 表单数据
const feedbackForm = ref({
  _id: '', // 初始化为空字符串
  username: '',
  contactInformation: '',
  feedbackContent: '',
  replyContent: '请输入回复内容',
  isPublic: 0 // 0 不公开, 1 公开
});

// 验证规则
const feedbackFormRules = ref({
  replyContent: [{required: true, message: "内容不能为空", trigger: "blur"}]
});

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 提交表单
const submitForm = async () => {
  feedbackFormRef.value.validate(async (valid) => {
    if (valid) {
      try {
        const formData = {
          _id: feedbackForm.value._id,
          replyContent: feedbackForm.value.replyContent,
          isPublic: feedbackForm.value.isPublic
        };

        await axios.put(`${process.env.VUE_APP_API_BASE_URL}/adminapi/feedback/update`, formData);
        ElMessage.success('提交成功');
        router.back();
      } catch (error) {
        console.error('提交表单出错:', error);
        ElMessage.error('提交失败，请重试');
      }
    }
  });
};

const handleBack = () => {
  router.back();
};

// 获取当前页的回复数据
onMounted(async () => {
  const res = await axios.get(`${baseUrl}/adminapi/feedback/list/${route.params.id}`);
  feedbackForm.value = reactive(res.data.data[0]);
});
</script>


<style lang="scss" scoped>
.el-form {
  margin-top: 30px;

  pre.feedback-content,
  textarea.reply-content {
    width: 100%;
    height: 260px;
    overflow-y: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    background-color: #f8f8f8;
    padding: 6px;
    border: 1px solid #ddd;
    font-family: 'Microsoft YaHei', sans-serif; /* 设置为微软雅黑 */
    font-size: 15px; /* 设置相同的字号 */
    line-height: 1.5; /* 设置统一的行高 */
    resize: vertical; /* 只允许垂直拉伸 */
    outline: none; /* 移除选中后的黑色边框 */
    text-indent: 2em; /* 段落首行缩进 */
  }

  textarea.reply-content {
    height: 320px;
  }

  button {
    margin: 0 auto;
  }
}
</style>
