<template>
  <el-card>
    <el-page-header
      content="专题列表"
      icon=""
      title="新闻管理"
      style="margin-bottom: 10px;"
    />

    <el-table :data="tableData" style="width: 100%; margin-top: 10px;">
      <el-table-column prop="category" label="专题分类" width="80" />
      <el-table-column prop="title" label="专题标题" width="580" />
      <el-table-column label="专题封面">
        <template #default="scope">
          <el-image
            v-if="scope.row.cover"
            :src="scope.row.cover"
            style="width: 180px; height: 90px;"
            fit="cover"
          />
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button
            v-if="scope.row.username !== 'system'"
            size="small"
            @click="handleEdit(scope.row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();
const tableData = ref([]);
const baseUrl = process.env.VUE_APP_API_BASE_URL;

const getTableData = async () => {
  const res = await axios.get(`${baseUrl}/adminapi/newsHot/list`);
  tableData.value = res.data.data;
};

const handleEdit = (item) => {
  router.push(`/news-manage/newsHotEdit/${item._id}`);
};

onMounted(getTableData);
</script>

<style lang="scss" scoped>

</style>
