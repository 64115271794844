<template>
  <div>
    <el-card>
      <el-page-header
          content="部门人员列表"
          icon=""
          title="编制管理"
      />

      <el-table
          :data="tableData"
          style="width: 100%; margin-top: 10px;"
      >
        <el-table-column
            prop="departmentId"
            label="id"
            width="0"
            v-if="false"
        />

        <el-table-column
            prop="sortOrder"
            label="序号"
            width="60"
        />

        <el-table-column
            prop="department"
            label="部门"
            width="180"
        />

        <el-table-column
            prop="staffNames"
            label="人员"
        />

        <el-table-column label="操作" width="120">
          <template #default="scope">
            <el-button
                circle
                :icon="Edit"
                @click="handleEdit(scope.row)"
            ></el-button>

            <el-popconfirm
                title="你确定要删除吗?"
                confirmButtonText="确定"
                cancelButtonText="取消"
                @confirm="handleDelete(scope.row)"
            >
              <template #reference>
                <el-button
                    circle
                    :icon="Delete"
                    type="danger"
                ></el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { Edit, Delete } from "@element-plus/icons-vue";
import { useRouter } from 'vue-router';
import { ElMessage } from "element-plus";

const router = useRouter();
const tableData = ref([]);

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

const handleError = (message, error) => {
  ElMessage.error(message);
  console.error(error);
};

const getTableData = async () => {
  try {
    const res = await axios.get(`${baseUrl}/adminapi/establishment/list`);

    tableData.value = res.data.data.map(item => ({
      id: item._id,
      sortOrder: item.sortOrder,
      department: item.name,
      staffIds: item.staffIds,
      staffNames: item.staffNames.join('、')
    }));
  } catch (error) {
    handleError('获取数据失败', error);
  }
};

// 删除回调
const handleDelete = async (item) => {
  try {
    await axios.delete(`${baseUrl}/adminapi/establishment/departmentStaff/${item.id}`);
    await getTableData();
  } catch (error) {
    handleError('删除失败', error);
  }
};

// 编辑回调
const handleEdit = (row) => {
  router.push({
    path: '/establishment-manage/establishmentEdit',
    query: {
      departmentId: row.id,
      departmentName: row.department,
      departmentStaffIds: row.staffIds
    }
  });
};

onMounted(() => {
  getTableData();
});
</script>

<style lang="scss" scoped>

</style>
