// 富文本编辑器
<template>
  <div id="ednfeditor" class="editor"></div>
</template>

<script setup>
import { onMounted, nextTick } from "vue";
import E from "wangeditor";

const emit = defineEmits(["event"]);
const props = defineProps({ content: String });

onMounted(() => {
  nextTick(() => {
    const editor = new E("#ednfeditor");
    editor.create();
    // 设置初始值
    if (props.content) {
      editor.txt.html(props.content);
    }
    editor.config.onchange = function (newHtml) {
      // 子传父
      emit("event", newHtml);
    };
  });
});
</script>

<style lang="scss" scoped>
.editor {
  width: 100%;
}
</style>
