import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Particles from "particles.vue3"
import '@/utils/axios.config'
import 'element-plus/dist/index.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';

const app = createApp(App)

app.use(Particles)
app.use(ElementPlus, { locale: zhCn });
app.use(store)
app.use(router)
app.mount('#app')

