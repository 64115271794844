import axios from 'axios';

const baseUrl = process.env.VUE_APP_API_BASE_URL

export async function uploadToAliyun(path, file) {
  try {
    // 获取阿里云上传参数
    const res = await axios.get(`${baseUrl}/uploads/aliyun_direct/${path}`);
    const { host, key, policy, accessid, signature, url } = res.data.data;

    // 构建 FormData 对象
    const formData = new FormData();
    formData.append('key', key);
    formData.append('policy', policy);
    formData.append('OSSAccessKeyId', accessid);
    formData.append('signature', signature);
    formData.append('file', file);

    // 上传图片到阿里云
    await axios.post(host, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return url;
  } catch (error) {
    console.error('上传文件到阿里云失败:', error);
    throw error;
  }
}
