export function getDefaultAvatar(gender) {
    switch (gender) {
        case 0:
            return '/avatar/avatar0.png';
        case 1:
            return '/avatar/avatar1.png';
        case 2:
            return '/avatar/avatar2.png';
        default:
            return '/avatar/avatar0.png'; // 默认头像
    }
}