<template>
  <div class="container">
    <el-page-header
        content="添加编制"
        icon=""
        title="编制管理"
    />
    <div class="form-container">
      <div class="department-container card">
        <el-collapse v-model="activeDepartment" accordion @change="updateDepartmentId">
          <el-collapse-item v-for="department in departmentList" :key="department.id" :name="department.id"
                            class="department-item">
            <template #title>
              <span>{{ department.name }}</span>
            </template>

            <span>负责人：</span>{{ department.director }} {{ department.directorPosition }}
            <div class="func-text">部门职责：{{ department.func }}</div>
          </el-collapse-item>
        </el-collapse>
      </div>

      <div class="staff-container card">
        <staffCheckbox
            :departmentId="activeDepartment"
            :departmentName="selectedDepartmentName"
            :departmentStaffs="selectedStaffIds"
        />
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref, onMounted, computed, watch } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";
import StaffCheckbox from "@/views/establishment-manage/staffCheckbox.vue";
import { useRoute } from 'vue-router';

const baseUrl = process.env.VUE_APP_API_BASE_URL; // 获取环境变量
const activeDepartment = ref(''); // 层叠面板的活动项
const departmentList = ref([]); // 部门列表

// 接收的 departmentId 参数
const route = useRoute();
const departmentId = route.query.departmentId;

// 更新部门ID
const updateDepartmentId = (val) => {
  activeDepartment.value = val;
};

// 获取部门数据
const getDepartments = async () => {
  try {
    const res = await axios.get(`${baseUrl}/adminapi/establishment/department`);
    const { ActionType, data } = res.data;

    if (ActionType === "OK") {
      departmentList.value = data.map(dept => ({
        id: dept._id,
        name: dept.name,
        director: dept.director,
        directorPosition: dept.directorPosition,
        staffIds: dept.staffIds,
        func: dept.func
      }));

      // 设置默认选中的部门
      activeDepartment.value = departmentList.value[0].id;
    } else {
      ElMessage.warning('部门数据为空或未正确返回');
    }
  } catch (error) {
    ElMessage.error('获取部门数据失败，请检查网络连接');
    console.error('Error:', error);
  }
};

// 当前选中的部门名称
const selectedDepartmentName = computed(() => {
  const selectedDepartment = departmentList.value.find(dept => dept.id === activeDepartment.value);
  return selectedDepartment ? selectedDepartment.name : '';
});

// 当前选中的部门人员ID列表
const selectedStaffIds = computed(() => {
  const selectedDepartment = departmentList.value.find(dept => dept.id === activeDepartment.value);
  return selectedDepartment ? selectedDepartment.staffIds : [];
});


// 页面加载时获取数据
onMounted(() => {
  getDepartments();
});

// 监听 departmentId 的变化
watch(() => departmentId, (newVal) => {
  if (newVal && departmentList.value.some(dept => dept.id === newVal)) {
    activeDepartment.value = newVal;
  }
});
</script>


<style lang="scss" scoped>
.container {
  button {
    display: block;
    margin: 16px 0 0 auto;
  }

  .form-container {
    margin-top: 30px;
    display: flex;
    gap: 20px;
  }

  .card {
    width: 100%;
    height: 768px;
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }

  .department-container {
    width: 240px;
    flex-shrink: 0;
  }

  .staff-container {
    padding: 20px;
  }

  .func-text {
    height: 70px; /* 默认4行的高度 */
    overflow: auto;
  }
}

::v-deep(.el-collapse-item__content) {
  padding: 0 0 0 6px;
background-color: #f6f6f6;
  border: 1px solid #e4e7ed;
}

::v-deep(.department-item) {
  .el-collapse-item__header {
    height: 40px;
    line-height: 40px;
    cursor: pointer;

    &.is-active {
      color: #409EFF; // 选中时的前景色
    }

    &:hover {
      background-color: #f0f9ff; // 鼠标移入时的背景色
    }
  }
}
</style>
