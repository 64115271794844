<template>
  <div>
    <el-card>
      <el-page-header
          content="用户列表"
          icon=""
          title="用户管理"
      />

      <el-table
          :data="tableData"
          style="width: 100%; margin-top: 10px;"
      >
        <el-table-column
            prop="username"
            label="用户名"
            width="180"
        />

        <el-table-column
            prop="nickname"
            label="昵称"
        />

        <el-table-column
            label="头像"
            width="100"
        >
          <template #default="scope">
            <div v-if="scope.row.avatar">
              <el-avatar :size="50" :src="scope.row.avatar"></el-avatar>
            </div>
            <div v-else>
              <el-avatar :size="50" :src="getDefaultAvatar(scope.row.gender)"></el-avatar>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="角色">
          <template #default="scope">
            <el-tag
                v-if="scope.row.role < 2"
                class="ml-2"
                type="danger"
            >管理员
            </el-tag>

            <el-tag
                v-else-if="scope.row.role === 2"
                class="ml-2"
                type="success"
            >总编辑
            </el-tag>

            <el-tag
                v-else-if="scope.row.role === 3"
                class="ml-2"
                type="warning"
            >编辑
            </el-tag>

            <el-tag
                v-else-if="scope.row.role === 4"
                class="ml-2"
                type="info"
            >纪检监察
            </el-tag>

            <el-tag
                v-else-if="scope.row.role === 5"
                class="ml-2"
                type="success"
            >普通用户
            </el-tag>

          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template #default="scope">
            <el-button
                size="small"
                @click="handleEdit( scope.row)"
            >编辑
            </el-button>
            <el-popconfirm
                title="你确定要删除吗?"
                confirmButtonText="确定"
                cancelButtonText="取消"
                v-if="scope.row.username !== 'system'"
                @confirm="handleDelete(scope.row)"
            >
              <template #reference>
                <el-button
                    size="small"
                    type="danger"
                    v-if="scope.row.username !== 'system'"
                >删除
                </el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 编辑弹窗 -->
    <el-dialog
        v-model="dialogVisible"
        title="编辑用户"
        width="50%"
    >
      <el-form
          ref="userFormRef"
          :model="userForm"
          :rules="userFormRules"
          label-width="80px"
          class="ruleForm"
      >
        <el-form-item
            label="用户名"
            prop="username"
        >
          <el-input v-model="userForm.username"/>
        </el-form-item>
        <el-form-item
            label="密码"
            prop="password"
        >
          <el-input
              v-model="userForm.password"
              type="password"
          />
        </el-form-item>

        <el-form-item
            label="角色"
            prop="role"
        >
          <el-select
              v-model="userForm.role"
              class="m-2"
              placeholder="Select"
              style="width:100%"
          >
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item
            label="职责"
            prop="responsibility"
        >
          <el-input
              v-model="userForm.responsibility"
              type="textarea"
          />
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button
                        type="primary"
                        @click="handleEditConfirm()"
                    >确认</el-button>
                </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {ref, onMounted, reactive} from "vue";
import axios from "axios";
import {getDefaultAvatar} from "@/utils/getDefaultAvatar";
import {ElMessage} from "element-plus";

const dialogVisible = ref(false);
const userFormRef = ref();
let userForm = reactive({
  username: "",
  password: "",
  role: 3, // 默认编辑
  responsibility: ""
});

// 表单验证
const userFormRules = reactive({
  username: [{required: true, message: "请输入名字", trigger: "blur"}],
  password: [{required: true, message: "请输入密码", trigger: "blur"}],
  role: [{required: true, message: "请选择权限", trigger: "blur"}],
  responsibility: [{required: true, message: "请输入介绍", trigger: "blur"}]
});

// 性别选择
const options = [
  {label: "管理员", value: 1},
  {label: "总编辑", value: 2},
  {label: "编辑", value: 3},
  {label: "纪检监察", value: 4},
  {label: "普通用户", value: 5}
];

// 表格数据
const tableData = ref([]);
onMounted(() => {
  getTableData();
});

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

const getTableData = async () => {
  const res = await axios.get(baseUrl + "/adminapi/user/list");
  tableData.value = res.data.data;
};

// 编辑回调
const handleEdit = async data => {
  const res = await axios.get(`${baseUrl}/adminapi/user/list/${data._id}`)
  Object.assign(userForm, res.data.data[0])
  dialogVisible.value = true;
};

//编辑确认回调
const handleEditConfirm = () => {
  userFormRef.value.validate(async (valid) => {
    if (valid) {
      await axios.put(`${baseUrl}/adminapi/user/edit/${userForm._id}`, userForm)
      dialogVisible.value = false
      await getTableData()
    }
  })
}

// 删除回调
const handleDelete = async data => {
  try {
    const response = await axios.delete(`${baseUrl}/adminapi/user/del/${data._id}`);
    if (response.data.ActionType === "OK") {
      ElMessage.success("删除成功");
    } else {
      ElMessage.error("删除失败");
    }
  } catch (error) {
    console.error("删除用户时出错:", error);
    ElMessage.error("删除失败");
  }

  await getTableData();
};
</script>

<style lang="scss" scoped>

</style>
