<template>
  <div class="centerContainer" ref="containerRef">
    <div class="content-container">
      <el-checkbox-group v-model="localSelectedStaffs" @change="handleSelectionChange">
        <el-row :gutter="0" class="staff-row">
          <el-col v-for="staff in paginatedStaffList" :key="staff.id">
            <div class="staff-item">
              <el-checkbox :value="staff.id">
                {{ staff.name }}
              </el-checkbox>
            </div>
          </el-col>
        </el-row>
      </el-checkbox-group>
    </div>

    <div class="pagination-container">
      <el-pagination
          layout="prev, pager, next"
          :total="staffList.length"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handlePageChange"
      />
      <el-button type="primary" @click="showConfirmDialog">提交</el-button>
    </div>

    <!-- 确认对话框 -->
    <el-dialog
        v-model="confirmDialogVisible"
        title="确认保存人员编制"
        width="30%"
        :center="true"
        class="confirm-dialog"
    >
      <div v-if="selectedStaffNames.length > 0" class="staff-names-container">
        <p class="staff-names-title">以下人员将被添加到 {{ props.departmentName }} 的编制中：</p>
        <div class="staff-names-grid">
          <div v-for="name in selectedStaffNames" :key="name" class="staff-name">
            {{ name }}
          </div>
        </div>
      </div>
      <div v-else>
        <p>没有选中的人员。</p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, nextTick, watch } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";

// 接收父组件传递的参数
const props = defineProps({
  departmentId: {
    type: String,
    default: ''
  },
  departmentName: {
    type: String,
    default: ''
  },
  departmentStaffs: {
    type: Array,
    default: () => []
  }
});

// 响应式数据
const staffList = ref([]);
const localSelectedStaffs = ref([]);
const containerRef = ref(null);
let confirmDialogVisible = ref(false); // 控制确认对话框的显示与隐藏
let resizeObserver = null; // 用于监听容器尺寸变化

// 分页相关数据
const currentPage = ref(1);
const pageSize = ref(30); // 初始每页显示30个选项

// 计算当前页显示的人员列表
const paginatedStaffList = computed(() => {
  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;
  return staffList.value.slice(start, end);
});

// 计算选中的人员名称
const selectedStaffNames = computed(() => {
  return staffList.value
      .filter(staff => localSelectedStaffs.value.includes(staff.id))
      .map(staff => staff.name);
});

// 处理分页变化
const handlePageChange = (newPage) => {
  currentPage.value = newPage;
};

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 获取人员数据
const getStaff = async () => {
  try {
    const res = await axios.get(`${baseUrl}/adminapi/staffCheckbox/staff`);
    const { ActionType, data } = res.data;

    if (ActionType === "OK" && Array.isArray(data)) {
      staffList.value = data.map(staff => ({ id: staff._id, name: staff.name }));

      // 在获取到人员数据后，设置初始选中的人员
      setInitialSelectedStaffs();
    } else {
      ElMessage.warning('人员数据为空或未正确返回');
    }
  } catch (error) {
    ElMessage.error('获取人员数据失败（staff）');
  }
};

// 计算列数和行数
const calculateColsAndRows = () => {
  if (containerRef.value) {
    const containerHeight = containerRef.value.clientHeight - 50;
    const containerWidth = containerRef.value.clientWidth;
    const itemHeight = 40; // 选项高度
    const minItemWidth = 200; // 选项最小宽度

    // 计算列数
    const cols = Math.max(Math.floor(containerWidth / minItemWidth), 1);

    // 计算行数，最多13行
    const maxRows = 13;
    const rows = Math.min(Math.floor(containerHeight / itemHeight), maxRows);

    return { cols, rows };
  }

  return { cols: 1, rows: 1 }; // 默认值，防止 undefined
};

// 计算每页显示的选项数量
const calculatePageSize = () => {
  const { cols, rows } = calculateColsAndRows();
  pageSize.value = cols * rows;

  // 重置当前页为第一页
  currentPage.value = 1;

  // 重新计算分页
  handlePageChange(1);
};

// 设置初始选中的人员
const setInitialSelectedStaffs = () => {
  localSelectedStaffs.value = props.departmentStaffs.filter(id => staffList.value.some(staff => staff.id === id));
};

// 组件挂载后执行
onMounted(() => {
  // 获取人员数据
  getStaff().then(() => {
    nextTick(() => {
      calculatePageSize();
    });
  });

  // 初始化 resize observer
  if (containerRef.value) {
    resizeObserver = new ResizeObserver(() => {
      nextTick(() => {
        calculatePageSize();
      });
    });
    resizeObserver.observe(containerRef.value);
  }
});

// 组件卸载时取消监听
onBeforeUnmount(() => {
  if (resizeObserver) {
    resizeObserver.unobserve(containerRef.value);
    resizeObserver = null;
  }
});

// 监听 props.departmentStaffs 的变化
watch(() => props.departmentStaffs, (newVal) => {
  setInitialSelectedStaffs();
});

// 处理选中变化
const handleSelectionChange = (newSelectedStaffs) => {
  emit('update-selected-staff-ids', newSelectedStaffs);
};

// 显示确认对话框
const showConfirmDialog = () => {
  confirmDialogVisible.value = true;
};

// 提交表单
const submitForm = async () => {
  if (localSelectedStaffs.value.length === 0) {
    ElMessage.warning('请选择至少一名人员');
    return;
  }

  try {
    const res = await axios.post(`${baseUrl}/adminapi/establishment/updateStaff`, {
      _id: props.departmentId,
      departmentStaffs: localSelectedStaffs.value
    });

    const { ActionType } = res.data;

    if (ActionType === "OK") {
      ElMessage.success("更新编制成功");
      confirmDialogVisible.value = false; // 关闭对话框
    } else {
      ElMessage.error('更新编制失败');
    }
  } catch (error) {
    ElMessage.error('请求失败，请稍后再试');
    console.error('Error in submitForm:', error); // 输出错误信息
  }
};

// 暴露选中的人员ID
const emit = defineEmits(['update-selected-staff-ids']);
</script>

<style lang="scss" scoped>
.centerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto; // 确保内容可以滚动
}

.staff-row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px; // 选项之间的间距

  .staff-item {
    height: 40px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    padding: 3px 12px; // 内边距
    box-sizing: border-box;

    .staff-checkbox {
      display: flex;
      align-items: center;
      white-space: nowrap; /* 防止内容换行 */
      overflow: hidden; /* 防止内容超出 */
      text-overflow: ellipsis; /* 超出部分用省略号表示 */
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.confirm-dialog {
  .el-dialog__header {
    text-align: center; // 对话框标题居中
    margin: 0 auto; // 水平居中;
  }

  .staff-names-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; // 使段落内容靠左对齐
    width: 100%;
  }

  .staff-names-title {
    text-align: left; // 段落内容靠左对齐
    margin-bottom: 10px; // 与下方内容的间距
  }

  .staff-names-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); // 两列布局
    gap: 10px; // 选项之间的间距
    width: 100%;
    max-width: 600px; // 最大宽度
    margin-top: 10px;
  }

  .staff-name {
    width: 100%;
    height: 40px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    padding: 10px; // 内边距
    box-sizing: border-box;
    text-align: center;
  }

  .dialog-footer {
    display: flex;
    justify-content: center; // 水平居中
    align-items: center; // 垂直居中
    margin-top: 20px;

    .el-button {
      margin: 0 10px; // 按钮之间的间距
    }
  }
}
</style>
