<template>
  <div>
    <el-card>
      <el-page-header
          content="反馈列表"
          icon=""
          title="反馈管理"
      />

      <el-table
          :data="tableData"
          style="width: 100%; margin-top: 10px;"
      >
        <el-table-column label="反馈时间" width="106">
          <template #default="scope">
            {{ formatTime.getTime(scope.row.feedbackTime) }}
          </template>
        </el-table-column>

        <el-table-column
            prop="feedbackContent"
            label="反馈内容"
        />

        <el-table-column label="回复时间" width="160">
          <template #default="scope">
            {{ scope.row.replyTime ? formatTime.getTime(scope.row.replyTime) : '' }}
          </template>
        </el-table-column>

        <el-table-column label="是否公开" width="100">
          <template #default="scope">
            <el-switch
                v-model="scope.row.isPublic"
                :active-value="1"
                :inactive-value="0"
                @change="handleSwitchChange(scope.row)"
            />
          </template>
        </el-table-column>

        <el-table-column label="操作" width="160">
          <template #default="scope">
            <el-button
                circle
                :icon="Star"
                type="success"
                @click="handlePreview(scope.row)"
            ></el-button>
            <el-button
                circle
                :icon="Edit"
                @click="handleEdit(scope.row)"
            ></el-button>

            <el-popconfirm
                title="你确定要删除吗?"
                confirmButtonText="确定"
                cancelButtonText="取消"
                @confirm="handleDelete(scope.row)"
            >
              <template #reference>
                <el-button
                    circle
                    :icon="Delete"
                    type="danger"
                ></el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog
        v-model="dialogVisible"
        title="预览回复内容"
        width="50%"
    >
      <div>
        <h2>{{ previewData.title }}</h2>
        <div style="font-size:12px;color:gray;">{{ formatTime.getTime(previewData.replyTime) }}</div>

        <el-divider>
          <el-icon>
            <star-filled/>
          </el-icon>
        </el-divider>

        <div
            v-html="previewData.replyContent"
            class="htmlcontent"
        ></div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {ref, onMounted} from "vue";
import axios from "axios";
import formatTime from "@/utils/formatTime";
import {Star, Edit, Delete, StarFilled} from "@element-plus/icons-vue";
import {useRouter} from 'vue-router'

const router = useRouter()
const tableData = ref([]);
const previewData = ref({});
const dialogVisible = ref(false);

onMounted(() => {
  getTableData();
});

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

const getTableData = async () => {
  const res = await axios.get(baseUrl + `/adminapi/feedback/list`);
  tableData.value = res.data.data;
};

//开关回调
const handleSwitchChange = async item => {
  await axios.put(baseUrl + `/adminapi/feedback/publish`, {
    _id: item._id,
    isPublic: item.isPublic
  });
  await getTableData();
};

//預覽回調
const handlePreview = data => {
  previewData.value = data;
  dialogVisible.value = true;
};

//删除回调
const handleDelete = async (item) => {
  await axios.delete(`${baseUrl}/adminapi/feedback/list/${item._id}`)
  await getTableData()
}

//编辑回调
const handleEdit = (item) => {
  //跳转到/feedback-manage-manage/editfeedback/:id
  router.push(`/feedback-manage/editfeedback/${item._id}`)
}
</script>

<style lang="scss" scoped>
h2 {
  margin-bottom: 10px;
}

::v-deep(.htmlcontent) {
  img {
    max-width: 100%;
  }
}
</style>
