<template>
  <div class="new-container container-style">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item @click="goBack">
        <div class="breadcrumb-item-content">
          <el-icon class="back">
            <Back/>
          </el-icon>
          <span class="text">新闻列表</span>
        </div>
      </el-breadcrumb-item>
      <el-breadcrumb-item>新闻预览</el-breadcrumb-item>
    </el-breadcrumb>

    <div v-if="loading" class="loading">
      加载中...
    </div>
    <div v-else-if="error" class="error">
      加载失败，请稍后再试。
    </div>
    <div v-else-if="currentNews.title" class="article-content">
      <h3>{{ currentNews.title }}</h3>
      <div class="meta-info">
        <span class="author">作者：{{ currentNews.author }}（信息来源：{{ currentNews.source }}）</span>
        <span class="time">发布时间：{{ whichTime(currentNews.publicationTime) }}</span>
        <div class="font">
          <span>【字体：</span>
          <span @click="setFontSize('small')">小</span>
          <span @click="setFontSize('default')">中</span>
          <span @click="setFontSize('large')">大</span>
          <span>】</span>
          <span class="clicks">阅读 {{ currentNews.clicks }}</span>
        </div>
      </div>
      <hr/>
      <div v-html="currentNews.content" :class="fontSizeClass"></div>
    </div>
    <div v-else class="no-data">
      没有找到相关新闻。
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import { Back } from "@element-plus/icons-vue";

moment.locale("zh-cn");

const route = useRoute();
const router = useRouter();
const currentNews = ref({});
const fontSize = ref('default'); // 默认字体大小
const loading = ref(true);
const error = ref(false);

const whichTime = time => moment(time).format("lll");

const goBack = () => router.back();

const setFontSize = (size) => {
  fontSize.value = size;
};

const fontSizeClass = computed(() => ({
  'font-default': fontSize.value === 'default',
  'font-small': fontSize.value === 'small',
  'font-large': fontSize.value === 'large'
}));

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

onMounted(async () => {
  if (!route.params.id) {
    loading.value = false;
    return;
  }

  try {
    const res = await axios.get(`${baseUrl}/adminapi/news/list/${route.params.id}`);
    if (Array.isArray(res.data.data) && res.data.data.length > 0) {
      currentNews.value = res.data.data[0];
    } else {
      currentNews.value = {};
    }
    loading.value = false;
  } catch (error) {
    loading.value = false;
    error.value = true;
  }
});
</script>

<style lang="scss" scoped>
.new-container {
  width: 1200px;
  background-color: #fdfdfd;
  margin: 0 auto;
  padding: 20px 20px;

  .el-breadcrumb {
    font-size: 16px;
  }

  .breadcrumb-item-content {
    display: flex;
    align-items: center;

    .back {
      margin-right: 6px;
      cursor: pointer;
    }

    .text {
      margin: 0;
    }
  }

  .article-content {
    h3 {
      text-align: center;
      margin-bottom: 16px;
    }

    .meta-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #666;
      margin-bottom: 10px;
      font-size: 14px;

      .author {
        flex: 1; // 使 author 占据剩余空间并居左
        text-align: left;
      }

      .time {
        flex: 1; // 使 time 占据剩余空间并居中
        text-align: center;
      }

      .font {
        flex: 1; // 使 font 占据剩余空间并居右
        text-align: right;

        span {
          margin: 0 5px;
        }

        .clicks {
          margin-left: 30px;
        }
      }
    }

    hr {
      border: none;
      border-top: 1px solid #ddd;
      margin: 10px 0;
    }

    .font {
      margin-bottom: 10px;

      span {
        margin-right: 10px;
        cursor: pointer;
      }
    }

    .content {
      p {
        font-size: 15px;
        line-height: 3; // 设置全局行距
        margin-bottom: 20px;
      }
    }

    .font-default {
      font-size: 16px;
      line-height: 1.8;
    }

    .font-small {
      font-size: 14px;
      line-height: 1.6;
    }

    .font-large {
      font-size: 18px;
      line-height: 1.9;
    }
  }

  .loading, .error, .no-data {
    text-align: center;
    margin-top: 20px;
    color: #666;
  }

  .error {
    color: red;
  }

  .no-data {
    color: #999;
  }
}
</style>
