<template>
  <div>
    <el-page-header
        content="添加部门"
        icon=""
        title="部门管理"
    />

    <el-form
        ref="departmentFormRef"
        :model="departmentForm"
        :rules="departmentFormRules"
        label-width="100px"
        class="ruleForm"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
              label="部门名称"
              prop="name"
          >
            <el-input v-model="departmentForm.name"/>
          </el-form-item>

          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item
                  label="负责人"
                  prop="director"
              >
                <el-input v-model="departmentForm.director"/>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                  label="职务"
                  prop="directorPosition"
              >
                <el-input
                    v-model="departmentForm.directorPosition"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item
                  label="第二负责人"
                  prop="deputy"
              >
                <el-input v-model="departmentForm.deputy"/>
              </el-form-item>

            </el-col>

            <el-col :span="12">
              <el-form-item
                  label="职务"
                  prop="deputyPosition"
              >
                <el-input
                    v-model="departmentForm.deputyPosition"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item
              label="电话"
              prop="phone"
          >
            <el-input v-model="departmentForm.phone"/>
          </el-form-item>

          <el-form-item
              label="地址"
              prop="address"
          >
            <el-input v-model="departmentForm.address"/>
          </el-form-item>

          <el-form-item
              label="邮箱"
              prop="email"
          >
            <el-input v-model="departmentForm.email"/>
          </el-form-item>


          <el-form-item>

            <el-button
                type="primary"
                @click="submitForm()"
            >
              添加部门
            </el-button>

          </el-form-item>

        </el-col>

        <el-col :span="12">
          <el-form-item
              label="部门职责"
              prop="func"
          >
            <el-input
                type="textarea"
                v-model="departmentForm.func"
                class="fixed-height-textarea"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script setup>
import {ref, reactive} from "vue";
import {useRouter} from 'vue-router'
import axios from "axios";
import {ElMessage} from "element-plus";

const router = useRouter();  // 初始化router

const departmentFormRef = ref();
const departmentForm = reactive({
  name: "",
  func: "",
  director: "",
  directorPosition: "",
  deputy: "",
  deputyPosition: "",
  phone: "",
  address: "",
  email: "",
  status: 1, // 部门状态 1:正常 0:禁用
  sortOrder: 100
});

// 表单验证规则
const departmentFormRules = reactive({
  name: [{required: true, message: "请输入部门名称", trigger: "blur"}],
});

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 提交表单数据
const submitForm = () => {
  departmentFormRef.value.validate(async (valid) => {
    if (valid) {
      try {
        const response = await axios.post(`${baseUrl}/adminapi/department/add`, departmentForm);
          ElMessage.success(response.data.Message);
          await router.push(`/department-manage/departmentlist`)
      } catch (error) {
        ElMessage.error("请求失败，请稍后再试");
        console.error(error);
      }
    }
  })
}

</script>

<style lang="scss" scoped>
.ruleForm {
  margin-top: 30px;

  button {
    margin-left: auto;
  }

  ::v-deep(.el-textarea__inner) {
    height: 278px;
    resize: none
  }
}
</style>
