<!-- 热点专题添加 -->
<template>
  <el-page-header
      content="添加图片"
      icon=""
      title="图片管理"
      style="margin-bottom: 30px;"
  />

  <CarouselForm/>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router'
import CarouselForm from "@/views/picture-manage/CarouselForm.vue";

const router = useRouter()
const route = useRoute()
</script>

<style lang="scss" scoped>

</style>