<template>
  <div>
    <el-page-header
        content="添加人员"
        icon=""
        title="人员管理"
    />

    <el-form
        ref="staffFormRef"
        :model="staffForm"
        :rules="staffFormRules"
        label-width="80px"
        class="ruleForm"
    >
      <el-row
          :gutter="10"
          class="el-row"
      >
        <el-col :span="12">
          <el-form-item
              label="姓名"
              prop="name"
          >
            <el-input v-model="staffForm.name"/>
          </el-form-item>

          <el-form-item
              label="电话"
              prop="phone"
          >
            <el-input v-model="staffForm.phone"/>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
              label="职务"
              prop="position"
          >
            <el-input v-model="staffForm.position"/>
          </el-form-item>

          <el-form-item
              label="邮箱"
              prop="email"
          >
            <el-input v-model="staffForm.email"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item
          label="家庭地址"
          prop="address"
      >
        <el-input v-model="staffForm.address"/>
      </el-form-item>

      <el-row
          :gutter="10"
          class="el-row"
      >
        <el-col :span="12">
          <el-form-item
              label="入职时间"
              prop="hireDate"
          >
            <el-date-picker
                v-model="staffForm.hireDate"
                type="date"
                placeholder="选择日期"
                style="width: 100%"
            />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
              label="学历"
              prop="education"
          >
            <el-input v-model="staffForm.education"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item
          label="个人简介"
          prop="biography"
      >
        <el-input
            v-model="staffForm.biography"
            type="textarea"
        />
      </el-form-item>

      <el-form-item
          label="分工"
          prop="responsibility"
      >
        <el-input
            v-model="staffForm.responsibility"
            type="textarea"
        />
      </el-form-item>

      <el-row
          :gutter="10"
          class="el-row"
      >
        <el-col :span="12">
          <el-form-item
              label="在职状况"
              prop="employmentStatus"
          >
            <el-select
                v-model="staffForm.employmentStatus"
                class="m-2"
                placeholder="请选择"
                style="width:100%"
            >
              <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
              label="性别"
              prop="gender"
          >
            <el-select
                v-model="staffForm.gender"
                class="m-2"
                placeholder="请选择"
                style="width:100%"
            >
              <el-option
                  v-for="item in genderOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item
          label="头像"
          prop="avatar"
      >
        <Upload
            :avatar="staffForm.avatar"
            @change="handleChange"
        />
      </el-form-item>

      <el-form-item>
        <el-button
            type="primary"
            @click="submitForm"
        >添加人员
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import Upload from "@/components/upload/Upload.vue";
import { useRouter } from 'vue-router';
import {uploadToAliyun} from "@/utils/uploadService";
import axios from "axios";

const router = useRouter();

const staffFormRef = ref();

// 表单数据
const staffForm = reactive({
  name: "",
  phone: "",
  position: "",
  email: "",
  address: "",
  hireDate: "",
  education: "",
  biography: "",
  responsibility: "",
  employmentStatus: 0,
  gender: 0,
  avatar: "",
});

// 表单验证
const staffFormRules = reactive({
  name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
  phone: [{ required: true, message: "请输入电话", trigger: "blur" }],
  avatar: [{ required: true, message: "请上传头像", trigger: "blur" }]
});

// 在职状况选择
const statusOptions = [
  { label: "在职", value: 0 },
  { label: "借调", value: 1 },
  { label: "休假", value: 2 },
  { label: "退休", value: 3 },
  { label: "死亡", value: 4}
];

// 性别选择
const genderOptions = [
  { label: "保密", value: 0 },
  { label: "男", value: 1 },
  { label: "女", value: 2 }
];

// 每次选择完图片之后的回调
const handleChange = file => {
  staffForm.avatar = URL.createObjectURL(file);
  staffForm.file = file;
};

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 查询人员是否存在
const checkStaffExists = async () => {
  try {
    const response = await axios.get(`${baseUrl}/adminapi/staff/check`, {
      params: {
        name: staffForm.name,
        phone: staffForm.phone
      }
    });
    return response.data.data.exists;
  } catch (error) {
    console.error("查询失败:", error);
    return false;
  }
};

// 提交表单
const submitForm = async () => {
  try {
    const valid = await staffFormRef.value.validate();
    if (valid) {
      const exists = await checkStaffExists();
      if (exists && !confirm("该人员已存在，确认要添加吗？")) {
        return; // 用户取消添加
      }

      // 更新 staffForm 中的文件路径为阿里云返回的文件 URL
      staffForm.avatar = await uploadToAliyun('staffuploads', staffForm.file);

      // 添加人员数据
      await axios.post(`${baseUrl}/adminapi/staff/add`, staffForm);

      // 跳转到人员列表页
      await router.push(`/staff-manage/stafflist`);
    }
  } catch (error) {
    console.error("提交失败:", error);
  }
};
</script>


<style lang="scss" scoped>
.ruleForm {
  margin-top: 30px;
}
</style>
