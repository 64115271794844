<template>
  <div>
    <ul>
      <li
          v-for="(directory, index) in directories"
          :key="index"
          :class="{ active: selectedDirectory === directory }"
          @click="selectDirectory(directory)"
      >
        {{ getDirectoryLabel(directory) }}
      </li>
    </ul>

    <div v-if="selectedDirectory">
      <h3>目录: {{ getDirectoryLabel(selectedDirectory) }}</h3>
      <div class="image-container">
        <div v-for="(image, index) in images" :key="index">
          <img :src="image" alt="Uploaded Image" />
        </div>
      </div>
    </div>

    <el-button type="primary" @click="handleCleanup(selectedDirectory)">清理残留图片</el-button>
  </div>
</template>


<script setup>
import axios from 'axios';
import { ElMessage } from 'element-plus';
import { ref, onMounted } from 'vue';

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

const directories = ['carouseluploads', 'newshotuploads', 'newsuploads', 'staffuploads', 'useruploads'];
const selectedDirectory = ref(null);
const images = ref([]);

const selectDirectory = async (directory) => {
  selectedDirectory.value = directory;
  try {
    const res = await axios.get(`${baseUrl}/adminapi/cleanup-images/list/${directory}`);
    images.value = res.data.data || [];
  } catch (error) {
    ElMessage.error('Failed to list images');
    images.value = []; // 确保 images 数组在错误情况下被重置
  }
};

const handleCleanup = async (directory) => {
  try {
    const res = await axios.post(`${baseUrl}/adminapi/cleanup-images/${directory}`);
      ElMessage.success(res.data.message);
  } catch (error) {
    ElMessage.error('无法清理图片');
  }
};

const getDirectoryLabel = (directory) => {
  switch (directory) {
    case 'carouseluploads':
      return '轮播图';
    case 'newshotuploads':
      return '新闻热点';
    case 'newsuploads':
      return '新闻封面';
    case 'staffuploads':
      return '部门人员';
    case 'useruploads':
      return '系统用户';
    default:
      return directory;
  }
};

onMounted(() => {
  // 默认选择第一个目录
  if (directories.length > 0) {
    selectDirectory(directories[0]);
  }
});
</script>


<style lang="scss" scoped>
.active {
  background-color: #f0f0f0;
}

ul {
  display: flex;
  list-style-type: none;
  padding: 0;
}

li {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  margin-right: 5px;
}

h3 {
  margin: 10px 0;
}

.image-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
}

img {
  max-width: 120px;
}

button {
  margin-top: 10px;
}
</style>

