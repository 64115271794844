export function getDefaultRole(role) {
    switch (role) {
        case 0:
            return '超级管理员';
        case 1:
            return '管理员';
        case 2:
            return '总编辑';
        case 3:
            return '编辑';
        case 4:
            return '普通用户';
        default:
            return '普通用户'; // 默认角色
    }
}